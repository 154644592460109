#MobileView {
    min-height: 500px;
}
.MobileSnapshot {
    width: 100%;
    max-width: 500px;
}
#MobileWM {
    position: absolute;
    top: 5px;
    left: 0px;
    height: 130px;
    .weightText {
        top: -17px;
    }
}
#Remote {
    position:absolute;
    top: 0px;
    left: 10px;
}
#Traffic {
    position: relative;
    top: 160px;
    height: 140px;
    padding:  10px;
    background: #d9d9d9;
    width: 100%;
    max-width: 260px;
}
#MobileCameraPanelDiv {
    margin-top: 10px;
}
#StreamWindow {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 100%;
    height: 100%;
    background: #404040;
    z-index: 2;
}

#StreamClose {
    position: absolute;
    left: 97%;
    color: white;
    font-size: 36pt;
}

#StreamFrame {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 95%;
    height: 97%;
}
.DocketPreview {
    font-size: 18pt;
    font-family: Consolas, Courier New, Courier, monospace !important;
    font-Weight: 800;
    height: 1200px;
    width: 600px;
}
.weightText {
    font-size: 56pt;
    font-style: italic;
    padding: 0px;
    position: relative;
    top: -7px;
    width: 250px;
    right: 5px;
    text-align: right;
}

.weightTextDiv {
    height: 70px;
    margin-top:40px;
}
// Remote Display
.remoteDisplay {
    font-size: 22pt;
    background-color: black;
    text-align: right;
    color: red;
    margin-top: 5px;
    width: 120px;
    max-height: 43px;
    overflow: clip;
    text-overflow: clip;
    margin-right: 10px;
}
