// window metricx
// window metricx
$width: 1505px; // width of available pane
$height: 735px; // height of available pane
$left: 190px; // left of window (menu)
$panelHeight: 270px; // height of weighbridge panel
$panelWidth: 645px; // width of weighbridge panel
$wbPanelLeft: 1050px; // left of wb panel
$wmblue: #2365b0;
$wmred: #f10707 ;
$greybtn: #676767;
$offline: #3b78af;

// Dialogs
.errorMessage {
    color: red;
    font-size: 22pt;
}
.dialogMessage {
    font-size: 22pt;
}

// Top of page
// Classes used by Weighbridge client application
#WeightBridgePanelDiv { // panel containing weighbridge controls,eg lights, weight
    position: absolute;
    right: 0px;
    margin: 60px 0px 0px 0px;
    background: $wmblue;
    padding: 0px;
}
// Remote Display
.remoteDisplay {
    font-size: 22pt;
    background-color: black;
    text-align: right;
    color: red;
    margin-top: 5px;
    width: 120px;
    max-height: 33px;
    overflow: clip;
    text-overflow: clip;
    margin-right: 10px;
}

// boom gates
.gates {
    //display: none;
    //margin-right: 10px;
}

// Traffic lights
.tlLabel {
    width: 100%;
    text-align: center;  
    color: white;
    font-weight: bold;
    font-size: larger;
}

// Weight meter
.weightText {
    font-size: 56pt;
    font-style: italic;
    padding: 0px;
    position: relative;
    top: -7px;
    width: 250px;
    right: 5px;
    text-align: right;
}
.weightTextDiv {
    height: 70px;
}
// split weights
.splitWeightText {
    font-size: 16pt;
}
.splitWeight {
    background: #eeeeee;
    width: 60px;
    text-align: right;
    margin: 9px
}
.splitWeightControl {
    padding: 5px;
    width: 95px;
    color: white;
    label {
        padding-bottom: 8px;
    }
}
// styling for deck weights
.deckWeightText {
    font-size: 16pt;
}
.deckWeight {
    margin-top: 10px;
    background: #eeeeee;
    width: 74px;
    height: 25px;
    text-align: right;
}
.deckPlus {
    margin-top: 10px;
    padding: 2px 8px; // spacing around "+"
}
.meterSpacing {
    position: relative;
    width: 341px;
}
// slider
.slider {
    width: 100%;
}

// scale selector
.activeScaleOptions {
    position:absolute;
    right: 410px;
    top: 130px;
    display: flex;
    max-width: 190px;
}

.activeScaleDiv { // button
    padding: 1px;
}

.activeScaleButton {
    max-width:80px;
}

// rego controls: Rego, accept etc
.showKeyboard {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}
#RegoDiv { //
    position: absolute;
    margin: 245px 0px 0px 10px;
    display: flex;
    .k-input-md {
        width: 270px;
    }
}
.RegoNoCameras {
    margin-top: 90px !important;
}

// load type div
.loadTypesDiv { // label + buttons
    position: absolute;
    margin: 340px 10px;
    max-width: 800px;
    height: 90px;
}
.LoadTypeNoCameras {
    margin-top: 180px !important;
}

.loadTypeOptions {
    display: flex;
}
.loadTypeDiv { // button
    padding: 3px;
}
.regoLookup {
    font-size: 36pt !important;
    width: 280px;
    margin-right: 10px !important;
}
.bigButton {
    height: 40px;
    margin-right: 10px;
    font-weight: bold ;
    background: $greybtn !important;
    color: white !important;
    z-index: 1;
}
.acceptButton {
    background: $wmblue !important;
}
.redButton {
    background: $wmred !important;
}
.bigButton span {
    font-size: 18pt;
}
.dropdownGap { // gap between load type and dropdowns
    min-height:20px;
}
.showAllJobs { // show all jobs div
    padding: 10px 0px;
}
.logSplit {
}

.restart {
    position: absolute;
    margin-top: 5px;
    right: 260px;
}
.reprint {
    position: absolute;
    margin-top: 5px;
    right: 420px;
}

// camera panel
#CameraPanelDiv { // div holding camera panel
    position: absolute;
    width: 100%;
    height: 240px;
    background: $wmblue;
}
#NoCameraDiv { // div for messages when cameras hidden
    position: absolute;
    width: 100%;
    height: 60px;
    background: $wmblue;
}
.cameraPanel { // Panel containing all snapshots
    margin-right: 20px;
    background: white;
    height: 200px;
    width: 774px;
    margin-top: 35px;
    margin-left: 10px;
    display: flex;
}
.cameraPanel1Camera {
    width: 260px !important;
}
.cameraPanel2Cameras {
    width: 515px !important;
}
.cameraSnapshot { // Individual snapshots
    max-width: 250px;
    max-height: 190px;
    margin: 5px 5px 5px 3px;
}

#FirstWeighDiv { // first weigh on WB page
    position: absolute;
    margin-top: 450px;
    margin-left: 15px;
    width: 70%
}
.FirstWeighNoCameras {
    margin-top: 200px !important;
}
 
// operator controls: load type, dropdowns
#OperatorDiv { //
    position: absolute;
    width:420px;
    right: 10px;
    margin-top: 260px;
}
// video streaming
#StreamWindow {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 100%;
    height: 100%;
    background: #404040;
    z-index: 2;
}
#StreamClose {
    position: absolute;
    left: 97%;
    color: white;
    font-size: 36pt;
}
#StreamFrame {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 95%;
    height: 97%;
}

// Signature Pad
#SignatureDiv {
    position: absolute;
    margin-top: 460px;
    margin-left: 50px;
    max-width: 400px;
}

.signaturePad {
    position: absolute;
    width: 400px;
    height: 250px;
}
.SignHereDiv {
    position: absolute;
    top: -60px;
    width: 500px;   
    background: red;
    color: white;
    font-size: large;
    z-index: 2;
    padding: 20px;
}
.signatureImage {
    width: 400px;
    height: 250px;
}
.signatureClear {
    position: absolute;
    left: 1700px;
}
.touchSignatureClear {
    position: absolute;
    left: 170px;
    .k-button {
        margin: 5px;
    }
}
// Item selector
.itemSelectorHeader {
    position: absolute;
    top: 2px;
    height: 50px;
    width: 97%;
    display: flex;
}
.itemSelectorTitle {
    padding-left: 20px;
    font-size: 24pt;
}
.itemSelectorSearch {
    margin: 3px;
    left: 450px;
    width: 300px;
    font-size: 20pt !important;
}
.itemSelectorClose {
    position: absolute;
    left: 96%;
}
.itemSelector {
    width: 80%;
    height: calc($height - 50px);
    width: calc($width - 30px);
}
.keyboardDiv {
    position: absolute;
    top: 40%;
    width: 90%;
    height: 200px
}
// virtual keyboard button size
.hg-button {
    height: 63px !important;
}
.hg-button span {
    font-size: 22pt;
}
// buttons
.itemSelectorDiv {
    position: absolute;
    top: 50px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 220px;
}
.itemSelectorButtonDiv {
    padding: 5px;
}
.itemSelectorButton {
    width: 220px;
    height: 100px;
}
.itemButtonText {
    max-width: 210px;
    max-height: 60px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow-y: clip;
    font-size: 16pt;
    font-weight: bold;
    white-space: normal;
    word-wrap: break-word;
}
.itemButtonImage {
    max-width: 300px;
    margin: 10px;
}
.itemButtonCode {
    max-width: 210px;   
    overflow: clip;
    font-size: 14pt;
}
// EFTPOS
.eftpos {
    width: 600px;
    height: 400px;
}
.eftposMessage {
    width: 90%;
    height: 310px;
    font-size: 16pt;
}
.eftposButton {
    margin: 10px;
    height: 70px;
    width: 130px;
}
.eftposInput {
    position: absolute;
    top: 280px;
    left: 33px;
    width: 90%;
}

// docket preview screen
#PreviewDiv {
    position:absolute;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 700px;
}
#DocketPreview {
    position: absolute;
    width: 420px;
    margin-top: 10px;
    right: 480px;
}
//docket preview control
.docketPreview {
    font-family: Consolas, Courier New, Courier, monospace !important;
    font-Weight: 800;
    height: 720px;
}
#TransactionSummary {
    position: absolute;
    width: 400px;
    padding: 5px;
    margin-top: 50px;
    top: 30%;
    right: 0px;
}
#Payments {
    position: absolute;
    top: 80px;
    margin: 5px;
    width: 50%;
    height: calc($height - 370px);
    padding: 5px;
}
#PreviewButtons {
    position: absolute;
    margin: 20px 20px;    
    width: 500px;
    height: 60px;
}
// tare list
.tableList {
    display:flex;
    padding: 3px;
}
.tableListName {
    width: 100px;
    padding: 3px;
}
.tableListValue {
    padding: 3px;
    font-weight: bold;
    width: 400px;
    background: #eeeeee;
    font-size: 16pt;
}
// Payment
.paymentGap {
    margin-top: 10px;
    display:flow;
    padding-right: 10px;
}
.paymentFeeDiv {
    width: 300px;
}
.paymentTotal {
    font-size: 24pt;
    background: #eeeeee;
    padding: 3px;
    width: 120px;
    text-align: right;
    margin-bottom:10px;
}
.paymentTotalLarge {
    width: 150px;
}

// virtual keyboard entry
#ItemSelector .k-dialog {
    width: 90%; // keyboard to resize to fit window
}
.virtualKeyboard .k-dialog{
    width: 100%;
}
.k-dialog-content {
    overflow: hidden !important; // prevent scrollbars
}
.textKeyboard {
    min-height: 680px;
}
#textExtraButton {
    position: absolute;
    top:  50px;
    width: 200px;
    height: 60px;
    top: 50px;
    left: 10px;
}
#textExtraButton2 {
    position: absolute;
    top: 50px;
    width: 200px;
    height: 60px;
    top: 50px;
    left: 220px;
}

.textKeyboardDiv {
    position: absolute;
    top: 130px;
    width: 95%;
    height: 600px;
    .react-simple-keyboard .hg-rows .hg-row .hg-button {
        height: 22% !important;

        span {
            font-size: 48pt;
        }
    }
}
.vkEntryHeader {
    position: absolute;
    left: 10px;
    top: 2px;
    height: 50px;
    width: 97%;
    display: flex;
}

.vkEntryTitle {
    padding-left: 20px;
    font-size: 28pt;
}

.vkEntryTextbox {
    margin: 3px;
    left: 0%;
    width: 70%;
    height: 100px;
    font-size: 48pt !important;
}

.vkEntryClose {
    position: absolute;
    top: 20px;
    left: 96%;
}
.vkOffence {
    position: absolute;
    top: calc($height - 90px);
    width: 95%;
}

// cash payment dialog
.paymentAmount {
    font-size: 36pt;
}
.cashAmounts {
    display: flex;
}

// Infomation box for weighbridge
.weighbridgeInfo {
    position: absolute;
    right: 5px;
    height: 65px;
    width: 100%;
}
.weighbridgeInfoTime {
    position:relative;
    top: 5px;
    width: 100%;
    text-align: right;
    font-size: 16pt;
    font-weight: bold;
    color: white;
}
.weighbridgeInfoVersion {
    position: relative;
    top: 15px;
    width: 100%;
    text-align: right;
    font-size: 12pt;
    color: white;
}
.weighbridgeInfoMessage {
    color: white;
    position: relative;
    top: -40px;
    left: 10px;
    font-size: 20pt;
    font-weight: bold;
    width: 800px;
}
.weighbridgeDocket {
    position: absolute;
    top: 40px;
    right: 128px;
    color: white;
    width: 120px;
}

#MixedProductsDiv {
    position: absolute;
    margin-left: 10px;
    margin-top: 420px;
    width: 800px;
}
#JobTonnage {
    width: 700px;
 }
#JobTonnage .row {
    padding: 10px;
    font-size: larger;
}
#JobTonnage .error {
    color: $wmred;
}
.DeviceOffline {
    background: $offline;
}

#HIDProcess {   
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: 1;
    background: white;
}

#HIDPanel1 {
    position: absolute;
    left: 22%;
    top: 25%;
    width: 27%;
    height: 52%;
    background: red;
    border-radius: 10px;
}
#HIDPanel2 {
    position: absolute;
    left: 50%;
    top: 25%;
    width: 20%;
    height: 52%;
    background: blue;
    padding: 10px;
    border-radius: 10px;
}
.HIDRow {
    display: flex;
}
.HIDEntry {
    width: 150px;
    font-size: 26pt;
    color: black;
    font-weight: bold;
    text-align: right;
    background: white;
    margin-top: 10px;
    padding-right: 5px;
    padding-top: 5px;
}
.HIDSmallEntry {
    position: absolute;
    left: 257px;
    width: 150px;
    font-size: 22pt;
    color: black;
    font-weight: bold;
    text-align: right;
    background: white;
    margin-top: 7px;
    padding-right: 5px;
    padding-top: 3px;
    height: 40px;
}
.HIDText {
    padding: 15px 10px;
    color: white;
    font-size: 24pt;
    font-weight: bold;
}
.HIDSmallText {
    padding: 10px;
    color: white;
    font-size: 16pt;
    font-weight: bold;
    margin-top: 6px;
}
.HIDSmallTonnes {
    position: absolute;
    left: 412px;
    color: white;
    font-size: 20pt;
    font-weight: bold;
    margin-top: 16px;
}
.HIDTotalEntry {
    margin-left:17px;
    font-size: 36pt;
}

.HIDTotal {
    padding: 10px;
    font-weight: bold;
    color: white;
    font-size: 28pt;
    margin-top: 10px;
}
#HIDScanID {
    position: absolute;
    left: 29%;
    top: 30%;
    width: 20%;
    height: 45%;
    background: $wmred;
    font-weight: bold;
    color: white;
    font-size: 28pt;
    padding: 140px 100px;
}
.HIDRedBtn {
    background: red;
    color: white;
    height: 50px;
    width: 100px;
    font-size: 16pt;
    font-weight: bold;
    padding: 13px;
    text-align: center;
    border-radius: 10px;
}
#HIDCancel {
    position: absolute;
    left: 35%;
    top: 80%;
}
#HIDBack {
    position: absolute;
    left: 45%;
    top: 80%;
}
#HIDConfirm {
    position: absolute;
    left: 55%;
    top: 80%;
    background: blue;
}
#TCBack {
    position: absolute;
    top: 10px;
    right: 100px;
}
.TCSelector {
    width: 80%;
    height: 790px;
    min-width: 1650px;
}
.TCButtonDiv {
    padding: 5px;
}
.TCSelectorDiv {
    position: absolute;
    top: 50px;
    display: flex;
    flex-wrap: wrap;
}
.TCButton {
    width: 300px;
    height: 150px;
}
.TCButtonText {
    max-width: 210px;
    max-height: 60px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow-y: clip;
    font-size: 16pt;
    font-weight: bold;
    white-space: normal;
    word-wrap: break-word;
}
.TCButtonImage {
    max-width: 300px;
    margin: 10px;
}

#CloudAvailableDiv {
    position: absolute;
    top: -60px;
    right: 180px;
}
