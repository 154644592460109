@media (max-width: 1300px) {
    #SignatureDiv {
        margin-top: 460px;
        margin-left: 5px;
        max-width: 500px;
    }

    #DocketPreview {
        width: 32%;
        margin-left: 520px;
        margin-top: 180px;
    }

    .docketPreview {
        font-family: "Courier";
        font-Weight: 800;
        font-size: 10pt;
        height: 540px;
    }

    #TransactionSummary {
        width: 180px;
    }

    .tableListName {
        width: 60px;
    }

    .tableListValue {
        font-size: 12pt;
        width: 140px;
    }

    .logSplit {
    }

    .acceptButton {
    }
}
